import {createSettingsParams, SettingsParamType} from '@wix/tpa-settings';

export type TextSettingsParams = keyof typeof settingsParams;
export type ISettingsParams = Record<TextSettingsParams, typeof settingsParams[TextSettingsParams]['type']>;

const settingsParams = {
  LOAD_MORE_BUTTON: {
    type: SettingsParamType.Text,
    getDefaultValue: () => null,
  },
  gallery_oosButtonText: {
    type: SettingsParamType.Text,
    getDefaultValue: () => null,
  },
  gallery_addToCartText: {
    type: SettingsParamType.Text,
    getDefaultValue: () => null,
  },
  gallery_preOrderText: {
    type: SettingsParamType.Text,
    getDefaultValue: () => null,
  },
  FILTERS_MAIN_TITLE: {
    type: SettingsParamType.Text,
    getDefaultValue: () => null,
  },
  SORTING_MAIN_TITLE: {
    type: SettingsParamType.Text,
    getDefaultValue: () => null,
  },
};

export default createSettingsParams<ISettingsParams>(settingsParams);
